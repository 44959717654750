import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import { graphql } from 'gatsby';

const ArchivePage = ({ data }) => {
  let years = new Set(
    data.allDirectusEdition.edges.map(({ node }) =>
      dayjs(node.date).format('YYYY')
    )
  );
  years = Array.from(years);
  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Archiv</h2>
          <p>Barcamp Events in den letzten Jahren</p>
        </header>
        <section className="wrapper style5 special">
          {years.map(year => (
            <div className="inner" key={year}>
              <h2 className="timetable__heading">{year}</h2>
              <ul className="inner timetable__list">
                {data.allDirectusEdition.edges.map(({ node }) => {
                  const isYear = dayjs(node.date).format('YYYY') === year;
                  return (
                    isYear && (
                      <li key={node.subtitle} className="timetable__item">
                        <Link to={'../' + node.date}>
                          {node.end_date === node.date ? (
                            <span>
                              {dayjs(node.date)
                                .locale(de)
                                .format('DD. MMMM YYYY')}
                              :
                            </span>
                          ) : (
                            <span>
                              {dayjs(node.date)
                                .locale(de)
                                .format('DD.')}{' '}
                              -{' '}
                              {dayjs(node.end_date)
                                .locale(de)
                                .format('DD. MMMM YYYY')}
                              :
                            </span>
                          )}
                          {' '}
                          {node.subtitle}
                        </Link>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          ))}
        </section>
      </article>
    </Layout>
  );
};

export default ArchivePage;

export const pageQuery = graphql`
  query ArchivQuery {
    allDirectusEdition(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          end_date
          subtitle
        }
      }
    }
  }
`;
